<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <!-- <debug-btn :item="responseData"></debug-btn> -->
          <template v-slot:heading>
            <h3>{{title}}</h3>
          </template>
          <v-btn-toggle mandatory v-model="triwulan" class="mt-5">
            <v-btn value="1">TRIWULAN 1</v-btn>
            <v-btn value="2">triwulan 2</v-btn>
            <v-btn value="3">triwulan 3</v-btn>
            <v-btn value="4">triwulan 4</v-btn>
            <v-btn value="tahun">tahunan</v-btn>
          </v-btn-toggle>
          <!-- <v-row> -->
            <!-- <v-col v-if="pegawai.length > 0">
              <div v-for="(peg,i) in pegawai" :key="i + 'a'"> -->
                <v-row v-if="loadingBtn.dataPegawai" class="mt-5">
                  <v-col v-for="i in [1,2,3,4,5,6]" :key="i">
                    <v-skeleton-loader
                    class="mx-auto"
                    min-width="300"
                    type="card"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row v-if="pegawai.length > 0" class="mt-10">
                  <v-col v-for="(peg,i) in pegawai" :key="i" align="center">
                    <base-material-card
                      class="v-card-profile"
                      :avatar="peg.foto"
                      style="width:270px; "
                    >
                      <v-card-text class="text-center" height='fit-content'>
                        <h4 class="display-2 mb-1 orange--text text--darken-2" style="height:55px;">
                          {{peg.nama}}
                        </h4>
                        <h4 class="font-weight-light grey--text mb-2">
                          NIP. {{peg.nip}}
                        </h4>
                        <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
                          {{peg.jabatan}}
                        </h4>
                        <div class="mt-5">
                          <!-- <v-chip small label :class="reviewStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip>
                          <v-chip small label class="ma-1 orange darken-2" dark>{{peg.jumlah}}/{{peg.harus}} Pertanyaan</v-chip> -->
                        </div>
                      </v-card-text>
                      <v-card-actions class="d-flex justify-center">
                        <div v-if="triwulan != 'tahun'">
                          <div v-if="(periode_umpan_balik && (triwulan_berjalan == triwulan))">
                            <v-btn
                            :color="triwulan == '1' ? peg.ekspektasi_perilaku_tw1 != null ? peg.ekspektasi_perilaku_tw1.bobot != null ? 'success' : 'primary' : 'primary' : 
                                    triwulan == '2' ? peg.ekspektasi_perilaku_tw2 != null ? peg.ekspektasi_perilaku_tw2.bobot != null ? 'success' : 'primary' : 'primary' : 
                                    triwulan == '3' ? peg.ekspektasi_perilaku_tw3 != null ? peg.ekspektasi_perilaku_tw3.bobot != null ? 'success' : 'primary' : 'primary' : 
                                    triwulan == '4' ? peg.ekspektasi_perilaku_tw4 != null ? peg.ekspektasi_perilaku_tw4.bobot != null ? 'success' : 'primary' : 'primary' : 
                                    peg.ekspektasi_perilaku_tahun != null ? peg.ekspektasi_perilaku_tahun.bobot != null ? 'success' : 'primary' : 'primary'"
                            rounded
                            class="mr-0 mt-2"
                            :loading="loadingElUmpanBalik == i"
                            @click="openDialogEkspektasiPerilaku(peg, i, true)"
                            :small="true">
                            <span> Isi Umpan Balik Ekspektasi Perilaku </span>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn
                            :color="triwulan == '1' ? peg.ekspektasi_perilaku_tw1 != null ? peg.ekspektasi_perilaku_tw1.bobot != null ? 'success' : 'primary' : 'primary' : 
                                    triwulan == '2' ? peg.ekspektasi_perilaku_tw2 != null ? peg.ekspektasi_perilaku_tw2.bobot != null ? 'success' : 'primary' : 'primary' : 
                                    triwulan == '3' ? peg.ekspektasi_perilaku_tw3 != null ? peg.ekspektasi_perilaku_tw3.bobot != null ? 'success' : 'primary' : 'primary' : 
                                    triwulan == '4' ? peg.ekspektasi_perilaku_tw4 != null ? peg.ekspektasi_perilaku_tw4.bobot != null ? 'success' : 'primary' : 'primary' : 
                                    peg.ekspektasi_perilaku_tahun != null ? peg.ekspektasi_perilaku_tahun.bobot != null ? 'success' : 'primary' : 'primary'"
                            rounded
                            class="mr-0 mt-2"
                            :loading="loadingElUmpanBalik == i"
                            @click="openDialogEkspektasiPerilaku(peg, i, true)"
                            :small="true"
                            v-if="enable.buka_umpan_balik_tw1 && triwulan == '1' ||
                                      enable.buka_umpan_balik_tw2 && triwulan == '2' ||
                                      enable.buka_umpan_balik_tw3 && triwulan == '3' ||
                                      enable.buka_umpan_balik_tw4 && triwulan == '4' ||
                                      enable.buka_umpan_balik_tahunan && triwulan == 'tahun'"
                            >
                            <span> Isi Umpan Balik Ekspektasi Perilaku </span>
                            </v-btn>
                          </div>
                        </div>
                        <div v-else>
                          <v-btn
                          :color="triwulan == '1' ? peg.ekspektasi_perilaku_tw1 != null ? peg.ekspektasi_perilaku_tw1.bobot != null ? 'success' : 'primary' : 'primary' : 
                                  triwulan == '2' ? peg.ekspektasi_perilaku_tw2 != null ? peg.ekspektasi_perilaku_tw2.bobot != null ? 'success' : 'primary' : 'primary' : 
                                  triwulan == '3' ? peg.ekspektasi_perilaku_tw3 != null ? peg.ekspektasi_perilaku_tw3.bobot != null ? 'success' : 'primary' : 'primary' : 
                                  triwulan == '4' ? peg.ekspektasi_perilaku_tw4 != null ? peg.ekspektasi_perilaku_tw4.bobot != null ? 'success' : 'primary' : 'primary' : 
                                  peg.ekspektasi_perilaku_tahun != null ? peg.ekspektasi_perilaku_tahun.bobot != null ? 'success' : 'primary' : 'primary'"
                          rounded
                          class="mr-0 mt-2"
                          :loading="loadingElUmpanBalik == i"
                          @click="openDialogEkspektasiPerilaku(peg, i, true)"
                          :small="true"
                          v-if="periode_umpan_balik_tahunan || (enable.buka_umpan_balik_tw1 && triwulan == '1' ||
                                    enable.buka_umpan_balik_tw2 && triwulan == '2' ||
                                    enable.buka_umpan_balik_tw3 && triwulan == '3' ||
                                    enable.buka_umpan_balik_tw4 && triwulan == '4' ||
                                    enable.buka_umpan_balik_tahunan && triwulan == 'tahun')"
                          >
                          <span> Isi Umpan Balik Ekspektasi Perilaku </span>
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </base-material-card>
                  </v-col>
                </v-row>
                <v-row v-else justify="center">
                  <v-col cols="5" v-if="loadingBtn.dataPegawai == false">
                    <v-alert
                    color="blue-grey"
                    dark
                    dense
                    icon="mdi-account-group"
                    prominent
                  >
                    Tidak ada pegawai
                  </v-alert>
                  </v-col>
                </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogFormEkspektasiPerilaku" persistent>
      <FormUmpanBalikEkspektasiPerilaku ref="formUmpanBalikEkspektasiPerilaku" :isUmpanBalik="isUmpanBalik" :dataPegawaiBawahan="dataPegawaiBawahan" :dataEkspektasiBawahan="dataEkspektasiBawahan" @onClose="(x)=>{dialogFormEkspektasiPerilaku=x}" @onUpdated="updateTable"/>
    </v-dialog>
  </v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import SKPService from '@/services/SKPService'
// import DebugBtn from '@/components/common/DebugBtn'
// import ConfirmBtn from '@/components/base/ConfirmBtn'
import {defaultRules} from '@/utils/lib'
import _g from '../../global'
import FormUmpanBalikEkspektasiPerilaku from './FormUmpanBalikEkspektasiPerilaku.vue'
import axios from 'axios'
export default {
  components:{
    // ConfirmBtn,
    // DebugBtn,
    FormUmpanBalikEkspektasiPerilaku
  },

  data(){
    return{
      valid:true,
      title:'Umpan Balik Berkelanjutan',
      forceUpdate:false,
      formDetail:{},
      formValidasi:{},
      formTolakKeterangan:"",
      idTolak:null,
      loading:false,
      detailData:{},
      triwulan:1,
      hides:[],
      dialogDetail:false,
      dialogValidasi:false,
      dialogTolak:false,
      selectedItem:{},
      tabItems:['Triwulan I', 'Triwulan II', 'Triwulan III', 'Triwulan IV', 'Tahunan'],
      tableValues:{},
      loadingBtn:{
        terima:false,
        tolak:false,
        validasi:false,
        dataPegawai:false
      },
      ratingList:['kurang', 'kurang berhasil', 'cukup', 'berhasil', 'sangat berhasil'],
      rules:{
        ...defaultRules,
        min30: v=> (v && v.length > 30) || 'Minimal 30 karakter'
      },
      dialogFormEkspektasiPerilaku: false,
      dataPegawaiBawahan: null,
      dataEkspektasiBawahan:{},
      // dataRespon:{},
      isUmpanBalik: false,
      loadingEl:-1,
      loadingElUmpanBalik:-1,
      triwulan_berjalan:null,
      periode_isi_ekspektasi:null,
      triwulan_enable:null,
      enable:false,
      periode_umpan_balik:null,
      periode_umpan_balik_tahunan:null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.triwulanFromRoute = to.query.jenis
    })
  },

  watch:{
    triwulan:function(val){
      console.log(val)
      if(this.triwulanFromRoute != null){
        if(val != this.triwulanFromRoute){
          this.triwulan = val
        }else{
          this.triwulan = this.triwulanFromRoute
        }
      }else{
        this.triwulan = val
      }
      this.updateTable()
    }
  },

  created(){
    this.year = new Date().getFullYear()-1
    this.triwulan = _g.getTriwulan().toString()
    this.updateTable()
  },

  computed:{
    triwulan_text(){
      return isNaN(this.triwulan ) ? 'Tahunan' : 'Triwulan ' + this.triwulan
    },

  },

  methods:{
    currentTriwulan(){
      let date = new Date()
      let month = date.getMonth() + 1;
      let triwulan = (Math.ceil(month / 3)).toString()
      if(month == 1){
        triwulan = "5"
      }
      return triwulan
      // if(this.tabTriwulan == triwulan)return true; else if(triwulan == 1) return true; else return false
    },

    updateTable(){
      this.pegawai=[]
      this.loadingBtn.dataPegawai = true
      // SKPService.getDataValidasiSKPTriwulan(this.triwulan).then(response =>{
      //   // this.responseData = response.data
      //   this.pegawai=response.data.data
      //   this.loadingBtn.dataPegawai = false
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }


      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-list-ekspektasi-perilaku'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: this.triwulan,
      }).then(response => {
        this.pegawai=response.data.data
        this.loadingBtn.dataPegawai = false
        this.triwulan_berjalan = response.data.triwulan_berjalan
        // this.periode_isi_ekspektasi = response.data.periode_isi_ekspektasi
        this.periode_umpan_balik = response.data.periode_umpan_balik_tw
        this.periode_umpan_balik_tahunan = response.data.periode_umpan_balik_tahunan
        this.triwulan_enable = response.data.triwulan_enable
        this.enable = response.data
      }).finally(()=>{
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })

      if(current.role != 'walikota'){
        SKPService.getSKP({
          search:'',
          row_per_page:1,
          triwulan:"tahun",
          page:5})
        .then((response)=>{
          // console.log('tahunan',response.data)
          this.itemsTahunan = response.data
          this.dataPegawaiEkspektasiPerilaku = response.data.data[0]
          if(this.tahun >= 2022){
            this.dataPegawaiEkspektasiPerilaku['ekspektasi_pimpinan'] = response.data.ekspektasi_pimpinan != undefined ? response.data.ekspektasi_pimpinan[0] : []
          }
          this.eselon = response.data.eselon
          this.jft = response.data.jft
          console.log(this.itemsTahunan)
        }).finally(()=>{
          this.loadingTahunan = false
        })
      }
    },

    closeDialog(){
      this.formValidasi = {}
      this.$refs.form.resetValidation()
      this.dialogValidasi=false
    },

    openDialogEkspektasiPerilaku(peg, idx, isUmpanBalik){
      this.loadingElUmpanBalik = idx
      this.isUmpanBalik = isUmpanBalik
      this.dataEkspektasiBawahan.e_berorientasi_pelayanan = ''
      this.dataEkspektasiBawahan.e_akuntabel = ''
      this.dataEkspektasiBawahan.e_kompeten = ''
      this.dataEkspektasiBawahan.e_harmonis = ''
      this.dataEkspektasiBawahan.e_loyal = ''
      this.dataEkspektasiBawahan.e_adaptif = ''
      this.dataEkspektasiBawahan.e_kolaboratif = ''
      this.dataEkspektasiBawahan.r_berorientasi_pelayanan = ''
      this.dataEkspektasiBawahan.r_akuntabel = ''
      this.dataEkspektasiBawahan.r_kompeten = ''
      this.dataEkspektasiBawahan.r_harmonis = ''
      this.dataEkspektasiBawahan.r_loyal = ''
      this.dataEkspektasiBawahan.r_adaptif = ''
      this.dataEkspektasiBawahan.r_kolaboratif = ''
      this.dataEkspektasiBawahan.tahun = this.$store.getters["user/data"].tahun
      // var current = store.state.user.data
      // SKPService.getEkspektasiPerilakuKerja(peg.nip, this.triwulan, this.dataEkspektasiBawahan.tahun).then(response=>{
      //   let res =response.data.data
      //   res.forEach(el => {
      //     if(el.nip_atasan == current.username){
      //       this.dataEkspektasiBawahan = el
      //     }
      //   });
      //   this.dataEkspektasiBawahan.foto = peg.foto
      //   this.dataEkspektasiBawahan.nip = peg.nip
      //   this.dataEkspektasiBawahan.jabatan = peg.jabatan
      //   this.dataEkspektasiBawahan.nama = peg.nama
      //   this.dataEkspektasiBawahan.jenis = this.triwulan
      // }).finally(()=>{
      //   // this.dataPegawaiBawahan = peg
      //   this.dialogFormEkspektasiPerilaku = true
      //   if(isUmpanBalik){
      //     this.loadingElUmpanBalik = -1
      //     // this.$refs.formUmpanBalikEkspektasiPerilaku.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //   }else{
      //     this.loadingEl = -1
      //     // this.$refs.formIsiEkspektasiPerilaku.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //   }
      //   // this.$nextTick(() => {
      //     // block: 'end' will scroll to top of element instead of bottom
      //     // this.$refs.formUmpanBalikEkspektasiPerilaku.$el.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
      //   // });
      // })
      // var date = new Date()
      // var bulan = date.getMonth()
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-ekspektasi-perilaku'
      axios.post(url_get_data, {
        nip_atasan: current.username, nip: peg.nip, triwulan: this.triwulan, tahun: this.dataEkspektasiBawahan.tahun
      }).then(response => {
        let res =response.data.data
        var arr = {}
        this.dataEkspektasiBawahan = arr
        if(this.triwulan == 'tahun'){
          if(res[0] != null){
            this.dataEkspektasiBawahan = res[0]
          }else{
            this.dataEkspektasiBawahan.bobot = null
          }
        }else{
          if(res != null){
            this.dataEkspektasiBawahan = res
          }else{
            this.dataEkspektasiBawahan.bobot = null
          }
        }
        // res.forEach(el => {
        //   if(el.nip_atasan == current.username){
        //     this.dataEkspektasiBawahan = el
        //   }
        // });
        this.dataEkspektasiBawahan.buka_umpan_balik = response.data.buka_umpan_balik
        this.dataEkspektasiBawahan.foto = peg.foto
        this.dataEkspektasiBawahan.nip = peg.nip
        this.dataEkspektasiBawahan.jabatan = peg.jabatan
        this.dataEkspektasiBawahan.nama = peg.nama
        this.dataEkspektasiBawahan.jenis = this.triwulan
        this.dataPegawaiBawahan = peg
        console.log("dataEkspektasiBawahan NIP : ", this.dataEkspektasiBawahan.nip)
        console.log("dataEkspektasiBawahan Nama : ", this.dataEkspektasiBawahan.nama)
        console.log("dataEkspektasiBawahan Foto : ", this.dataEkspektasiBawahan.foto)
      }).finally(()=>{
        this.dialogFormEkspektasiPerilaku = true
        if(isUmpanBalik){
          this.loadingElUmpanBalik = -1
        }else{
          this.loadingEl = -1
        }
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

  },
}
</script>

<style lang="css" scoped>
</style>
